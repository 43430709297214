.tooltip2 {
  visibility: hidden;
  /* Or display: none, depending on how you want it to behave */
  /*display: none;*/
  /*background-color: lawngreen;*/
}

.tooltipOnHover {
  position: relative;
  top: -43px;
  left: -13px;
  /*background-color: aqua;*/
}

.tooltipOnHover:hover + .tooltip2, .tooltip2:hover { /* Uses the adjacent sibling selector */
  visibility: visible;
  /*display: block;*/

  /*background-color: green;*/
  width: 70px;
  /*height: 90px;*/
  position: relative;
  top: -155px;
  left: -18px;
  /*margin: 5px;*/
  padding: 30px;
  z-index: 100;
}

.innerTooltip {
  background-color: white;
  border-color: grey;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  font-size: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  line-height: 20px;
  padding: 15px;
  display: inline-block;
  white-space: nowrap;
  cursor: default;
}

.innerTooltip a {
  color: blue;
  text-decoration: underline;
}
