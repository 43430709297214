.headerRow {
  background:transparent url('./backGround2.png') repeat-x;
  background-size: auto 160px;
  /*height: 280px;*/
}

@media only screen and (min-width: 768px) {

  .headerTitle {
    padding-top: 45px;
  }
}

@media only screen and (max-width: 400px) {

  .headerTitle {
    font-size: 2rem;
  }
}


.headerTitle {
  font-weight: 700;
  text-align: right;
  font-size: 2.5rem;
}


.sliderBlock {
  width: 358px;
  height: 226px;
  position: relative;
  top: 20px;
  left: 10px;
  background-color: white;
  border-color: whitesmoke;
  border-width: 4px;
  border-style: solid;
  /*border-radius: 4px;*/
  margin-bottom: 50px;
}

.sliderBlock img {
  width: 100%;

}

.articleCol {
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
  padding-left: 7px;
  padding-right: 7px;
}

.photoCell img {
  width: 100%;
  /*position: relative;*/
}

.photoCell article {
  padding: 10px;
  position: absolute;
  top: 10px;
  left: 0px;
  width: 100%;
  text-align: center;
  font-size: 50px;
  color: white;
  padding: 2px;
  font-style: italic;
}

.homePageLinkWrapper {
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 100%;
  text-align: center;
}

.homePageLink {
  text-decoration: none;
  color: #0d6efd;
  font-size: 18px;
}

.homePageLink:hover {
  text-decoration: underline;
}

.visitMeLabel {
  text-decoration: none;
  color: #0d6efd;
  background-color: #eaf0f6;
  padding: 6px;
  font-size: 18px;
}

.visitMeLabel:hover {
  text-decoration: underline;
}

.headerArticle2 img {
  width: 100%;
  /*position: relative;*/
}

.headerArticle2 article {
  padding: 10px;
  padding-bottom: 40px;
}


.headerArticle2, .photoCell {
  background-color: #eaf0f6;
  font-size: 22px;
  /*height: 300px;*/
  margin-bottom: 0px;
  /*box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.5);*/
  /*box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);*/
  /*transition: height 0.3s, box-shadow 0.3s;*/
  transition: all 200ms ease-in;
  transform: scale(1);
  height: 100%;
}

.headerArticle2:hover, .photoCell:hover {
  box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.5);

  transition: all 200ms ease-in;
  transform: scale(1.02);
  cursor: pointer;
}
