/* not used */
.scrollAnchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

.gumshoeRoot {
  padding-left: 0;
  list-style: none;
}

.gumshoeActive {
  background-color: #343a40;
}

.gumshoeActive a {
  color: #fff;
}

.markdownContent img {
  max-width: 100%;
}

.sideMenuNav {
  text-decoration: none;
}

.sideMenuNav:hover {
  text-decoration: underline;
}