.rentalView {
  width: 260px;
  max-width: 260px;
  border: 1px solid #aaa;
}

.rentalTable {
  /*font-family:  "Trebuchet MS", Arial, Helvetica, sans-serif;*/
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
}

.rentalTable td, .rentalTable th {
  border: 1px solid #ddd;
  padding: 4px;
}

.rentalTable tr:nth-child(even){background-color: #f2f2f2;}

.rentalTable tr:hover {background-color: #ddd;}

.rentalTable th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #b6cde1;
}
